import React from 'react'

// Utils
import { capitalize } from '@/utils'

// Styling
import styles from './Tag.module.scss'

const categories = new Set([
  'design',
  'dev',
  'user-experience',
  'research',
  'company',
  'closed'
])

const Tag: React.FC<TagProps> = ({
  category = 'TagComponent',
  color,
  isInversed = false,
  className = '',
  children
}) => {
  const isValidCategory = categories.has(category)
  const tagColor = color ?? `var(--${category})`
  const tagTextColor = color ?? isValidCategory ? `var(--${category}-text)` : 'var(--sweet-liquorice-900)'

  return (
    <span
      className={`
        ${styles.tag}
        ${!isValidCategory ? (isInversed ? styles.inversed : '') : ''}
        ${children ? styles['with-children'] : ''}
        ${className}
      `}
      style={{
        '--color': tagColor,
        '--text-color': tagTextColor
      } as React.CSSProperties}
    >
      <span className="body-s">
        {children ?? category
          .split('-')
          .map((word: string) => capitalize(word))
          .join('')}
      </span>
    </span>
  )
}

export default Tag
