import React from 'react'
import { m } from 'framer-motion'

const transition = {
  duration: 0.75,
  delay: 1,
  ease: 'easeInOut',

  /*
   * Both 'stops' take up 10% of the animation time, which means the three animations itself are
   * 80 / 3 = 26.7% each. The duration can change, but the relative values stay the same
   */
  times: [
    0,
    0.267,
    0.367,
    0.634,
    0.734,
    1
  ]
}

export default function Strikethrough({
  className = ''
}: {
  className: string
}) {
  return (
    <svg
      className={className}
      fill="none"
      height="28"
      viewBox="0 0 114 28"
      width="114"
      xmlns="http://www.w3.org/2000/svg"
    >
      <m.path
        animate={{
          pathLength: [
            0,
            0.43,
            0.45,
            0.8,
            0.82,
            1
          ]
        }}
        d="M2.38978 15.1997C30.7688 15.1997 111.949 2.00003 111.949 2.00003C89.5071 16.5198 34.714 23.227 24.1686 23.7797C10.9869 24.4705 60.002 27.9041 75.6473 23.7797"
        fill="transparent"
        initial={{ pathLength: 0 }}
        stroke="#18DCD9"
        strokeDasharray="0"
        strokeWidth="4"
        transition={transition}
      />
    </svg>
  )
}
