import { useEffect, useState } from 'react'

const generateSources = (src: string, breakpoints: number[], isWebp: boolean) => {
  return breakpoints.map((breakpoint) => {
    return `${src}${src.includes('?') ? '&' : '?' }w=${breakpoint}${isWebp ? '&fm=webp' : ''} ${breakpoint}w`
  })
}

/**
 * Generate source sets for Dato, this is used so that it renders the correct sizes.
 *
 * This should greatly reduce the size of the image loaded in header images.
 * For now this is only used in section header as this affects FCP.
 *
 * It can be used everywhere where relevant, it's very easy to implement.
 * It would most likely benefit most places where this would be used.
 *
 * @param {string} src - The source of the image (responsiveImage.src)
 * @param {number[]} breakpoints - The different widths to render at example: [300, 400, 500, 600]
 * @param {number} srcWidth - The width to use for the fallback image
 */
function useImageSizes(src: string, breakpoints: number[], srcWidth: number): {
  webpSrcSet: string,
  srcSet: string,
  src: string,
} {
  const [webpSrcSet, setWebpSrcSet] = useState(() => {
    return generateSources(src, breakpoints, true).join(',\n')
  })

  const [srcSet, setSrcSet] = useState(() => {
    return generateSources(src, breakpoints, false).join(',\n')
  })

  const [finalSrc, setFinalSrc] = useState(`${src}${src.includes('?') ? '&' : '?' }w=${srcWidth}`)

  useEffect(() => {
    const newWebpSrcSet = generateSources(src, breakpoints, true)
    setWebpSrcSet(newWebpSrcSet.join(',\n'))

    const newSrcSet = generateSources(src, breakpoints, false)
    setSrcSet(newSrcSet.join(',\n'))

    setFinalSrc(`${src}${src.includes('?') ? '&' : '?' }w=${srcWidth}`)
  }, [
    src,
    breakpoints,
    srcWidth
  ])

  return {
    webpSrcSet,
    srcSet,
    src: finalSrc
  }
}

export default useImageSizes
