import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import {
  m,
  AnimatePresence,
  LazyMotion,
  domAnimation
} from 'framer-motion'
import { wrap } from 'popmotion'

// Styling
import styles from './HeaderBase.module.scss'

// Types
interface RotatingAnimationProps {
  items: ListObject[]
}

const variants = {
  enter: () => {
    return {
      display: 'none',
      opacity: 0,
      y: 80
    }
  },
  center: {
    opacity: 1,
    display: 'inline-flex',
    y: 0
  },
  exit: () => {
    return {
      y: -80,
      opacity: 0,
      transition: {
        delay: 0,
        duration: .2
      }
    }
  }
}

const renderLetters = (word: string) => {
  const letters = [...word]

  return letters.map((letter: string, idx: number) => {
    return (
      <LazyMotion features={domAnimation} key={idx} strict>
        <span className={styles.wrapper}>
          <m.span
            animate="center"
            exit="exit"
            initial="enter"
            transition={{
              duration: 0.5 * 0.92 ** idx,
              ease: [
                .09,
                1.12,
                .37,
                1
              ],
              opacity: {
                duration: 0.5 * .94 ** idx,
                delay: idx * 0.1
              },
              delay: idx * 0.1
            }}
            variants={variants}
          >
            {letter}
          </m.span>
        </span>
      </LazyMotion>
    )
  })
}

export const RotatingAnimation: React.FC<RotatingAnimationProps> = ({ items }) => {
  const [[title], setTitle] = useState([0])
  const imageIndex = wrap(0, items.length, title)

  const paginate = useCallback(() => {
    setTitle([title + 1])
  }, [title])

  useEffect(() => {
    const timer = setTimeout(() => paginate(), 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [paginate])

  return (
    <span className={styles.anim}>
      <span className={styles['screen-reader-only']}>{items.join(', ')}</span>
      <AnimatePresence initial={false}>
        <LazyMotion features={domAnimation} strict>
          <m.span
            aria-hidden="true"
            key={title}
          >
            {renderLetters(items[imageIndex] as unknown as string)}
          </m.span>
        </LazyMotion>
      </AnimatePresence>
    </span>
  )
}
