import React, { useEffect, useRef } from 'react'
import { Image } from 'react-datocms'
import { saturate } from 'color2k'
import { useInView } from 'react-intersection-observer'
import { captureException } from '@sentry/nextjs'

// Styles
import styles from './LazyImage.module.scss'
import { useImageSizes } from '@/hooks'

interface ResponsiveImageType {
  /** The aspect ratio (width/height) of the image */
  aspectRatio?: number;
  /** A base64-encoded thumbnail to offer during image loading */
  base64?: string | null;
  /** The format type (file extension of the image or video */
  format?: string;
  /** The mimetype and filetype of the video */
  mimeType?: string | null
  /** The height of the image */
  height?: number;
  /** The width of the image */
  width: number;
  /** The HTML5 `sizes` attribute for the image */
  sizes?: string | null;
  /** The fallback `src` attribute for the image */
  src?: string | null;
  /** The HTML5 `srcSet` attribute for the image */
  srcSet?: string | null;
  /** The HTML5 `srcSet` attribute for the image in WebP format, for browsers that support the format */
  webpSrcSet?: string | null;
  /** The background color for the image placeholder */
  bgColor?: string | null;
  /** Alternate text (`alt`) for the image */
  alt?: string | null;
  /** Title attribute (`title`) for the image */
  title?: string | null;
  /** Url of the image */
  url?: string | null;
}

interface BaseVideoType extends ResponsiveImageType {
  /** The mimetype and filetype of the video */
  mimeType?: string | null
  /** The mux video format for the video file */
  video?: {
    src?: string | null
    poster?: string | null
  } | null
}

// Types
interface LazyImageProps {
  /** The data responsive */
  data: BaseVideoType,
  /** Loading bubble color */
  color?: string | null,
  /** Additional CSS className for the component wrapper element */
  className?: string,
  /** Additional CSS className for 2nd wrapper element */
  imageWrapperClassName?: string
  /** Additional CSS className for root node */
  imageRootClassName?: string
  /** Additional CSS class for the image inside the `<picture />` tag */
  imageClassName?: string
}

function useColor(color: string) {
  const [colorValue, setColorValue] = React.useState('')

  useEffect(() => {
    setColorValue(saturate(color, 0.5))
  }, [color])

  return {
    lightColor: color,
    darkColor: colorValue
  }
}

function setStyles(
  el: HTMLSpanElement,
  diameter: number,
  startColor: string,
  endColor: string
) {
  el.style.setProperty('--start-color', startColor)
  el.style.setProperty('--end-color', endColor)
  el.style.width = `${diameter}px`
  el.style.height = `${diameter}px`
  el.classList.add(styles.bubble)
}

function removeExistingBubbles(el: HTMLDivElement) {
  const bubbles = el.querySelectorAll(`.${styles.bubble}`)
  for (let idx = bubbles.length - 1; idx >= 0; idx -= 1) {
    bubbles[idx].remove()
  }
}

/**
 * Image lazy loading animation component.
 * Animates a bubble on top over the image that fades out.
 */
function LazyImage({
  data,
  color = '#e7ecf0',
  className = '',
  imageWrapperClassName = '',
  imageRootClassName = '',
  imageClassName = ''
}: LazyImageProps) {
  const { lightColor, darkColor } = useColor(color ?? '#e7ecf0')
  const {
    srcSet,
    src,
    webpSrcSet
  } = useImageSizes(
    data.src ?? '',
    [
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000,
      1200,
      1400,
      1600,
      1800,
      2000,
      2816,
      3072,
      3456,
      3840,
      4096,
      5120
    ],
    1400
  )

  // Refs
  const bubbleBackgroundRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  // Intersection observer
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  function createLoadingBubble() {
    const imageEl = imageRef.current
    if (imageEl !== null) {
      const { width, height } = imageEl.getBoundingClientRect()
      const diameter = Math.max(width, height)

      // Clean and create new bubble
      removeExistingBubbles(imageEl)
      const bubble = document.createElement('span')
      setStyles(bubble, diameter, lightColor, darkColor)
      imageEl.insertBefore(bubble, imageEl.childNodes[0])

      // Remove the circle after the animation is done.
      bubble.addEventListener('animationend', () => {
        if (bubbleBackgroundRef.current !== null) {
          bubbleBackgroundRef.current.style.opacity = '0'
        }

        bubble.style.opacity = '0'
      })
    }
  }

  useEffect(() => {
    if (inView) {
      createLoadingBubble()

      if (videoRef.current) {
        videoRef.current.play()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!data) {
      throw new Error('Data prop is required, please make sure it\'s provided')
    }

    if ((data.format === 'mp4' || data.format === 'webm') && !data.video?.src) {
      captureException(new Error('Video src is required when a element is a video, otherwise the video will break on mobile. Please ensure that the graphql is correct.'))
    }
  }, [data])

  return (
    <div className={className} ref={ref}>
      <div
        className={`
          ${styles.wrapper}
          ${imageWrapperClassName}
        `}
        ref={imageRef}
      >
        <div
          className={styles['bubble-background']}
          ref={bubbleBackgroundRef}
        />
        {
          (data.format === 'mp4' || data.format === 'webm')
            ? (
              <video
                autoPlay
                className={imageClassName}
                controls={false}
                loop
                muted
                playsInline
                poster={data.video?.poster?.replace('jpg', 'webp') ?? ''}
                preload="none"
                ref={videoRef}
              >
                <source src={data.url ?? ''} type={data.mimeType ?? ''} />
                {data.video?.src && <source src={data.video.src} type="video/mp4" /> }
              </video>
            )
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            : data && (
              <Image
                className={imageRootClassName}
                // @ts-expect-error This is fine, this is because of how type generates
                data={{
                  ...data,
                  src,
                  srcSet,
                  webpSrcSet
                }}
                pictureClassName={imageClassName}
                style={{ backgroundColor: `${lightColor}` }}
              />
            )
        }
      </div>
    </div>

  )
}

export default LazyImage
