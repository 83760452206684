import * as React from 'react'
import Head from 'next/head'
import { renderMetaTags } from 'react-datocms'

// Types
import type { SeoMetaTagType } from 'react-datocms'

// This ensures a type error is thrown if the slug starts with a slash
type allowedStarts = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z'
type NonSlashString = `${allowedStarts}${string}` | ''

interface IMetaHeadProps {
  metaTags: SeoMetaTagType[]
  slug: NonSlashString
}

export default function MetaHead({
  metaTags,
  slug,
  children
}: React.PropsWithChildren<IMetaHeadProps>) {
  if (slug.startsWith('/')) {
    throw new Error('Slug should not start with a slash')
  }

  return (
    <Head>
      {renderMetaTags(metaTags)}
      {slug === ''
        ? (
          <meta content="https://yummygum.com" property="og:url" />
        )
        : (
          <meta content={`https://yummygum.com/${slug}`} property="og:url" />
        )}
      {children}
    </Head>
  )
}
