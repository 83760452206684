import React, { useEffect, useState } from 'react'
import Link from 'next/link'

// Components
import Icon from '@/components/atoms/Icon'

// Helpers
import { useMediaQuery } from '@/hooks'

// Styling
import styles from './Breadcrumb.module.scss'

// Types
import { IconRotate, IconSize } from '@/lib/enums'

export interface BreadcrumbProps {
  label?: string
  items?: LinkType[]
  className?: string
  single?: boolean
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  label = 'Back to',
  items = [],
  className = '',
  single
}) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState(items)
  const isMobile = useMediaQuery('(max-width: 562px)')

  if (breadcrumbItems[0].title?.includes('#')) {
    const [firstCrumb] = breadcrumbItems[0].title.split('#')
    breadcrumbItems[0].title = firstCrumb
  }

  useEffect(() => {
    if (!isMobile || single) {
      setBreadcrumbItems(items)
    } else {
      const mobileItems = [...items]
      const indexToRemove = 0
      const numberToRemove = items.length - 2

      // Remove misc items from array
      mobileItems.splice(indexToRemove, numberToRemove)
      mobileItems.pop()

      setBreadcrumbItems(mobileItems)
    }
  }, [
    items,
    isMobile,
    single
  ])

  return (
    <nav aria-label="Breadcrumb">
      <ol
        className={`
          ${styles.breadcrumb}
          ${className}
        `}
      >
        {
          (isMobile && !single) && (
            <p className="body-l">
              <Link href={breadcrumbItems[0].path ?? ''} scroll={false}>
                <a
                  className="body-l underline"
                  href={breadcrumbItems[0].path}
                >
                  <Icon
                    className={styles.chevron}
                    direction={IconRotate.West}
                    name="chevron"
                    size={IconSize.Large}
                  />
                  <span className={`${styles.back}`}>{label}</span>

                  {breadcrumbItems[0].title}
                </a>
              </Link>
            </p>
          )
        }

        {breadcrumbItems.map(({ path, title: breadTitle }, idx) => (
          <li
            className={`${styles.item} ${isMobile && !single ? styles.hidden : ''} `}
            key={idx}
          >
            {
              idx < (breadcrumbItems.length - 1) && (
                <>
                  {path !== ''
                    ? (
                      <Link href={path ?? ''} scroll={false}>
                        <a
                          className="body-l underline"
                          href={path}
                          {...(breadcrumbItems.length === idx + 1 && { 'aria-current': 'page' })}
                        >
                          {breadTitle}
                        </a>
                      </Link>
                    )
                    : (
                      <p
                        className="body-l"
                      >
                        {breadTitle}
                      </p>
                    )}
                </>
              )
            }
            {
              idx < (breadcrumbItems.length - 1) && (
                <Icon
                  className={styles.chevron}
                  direction={IconRotate.East}
                  name="chevron"
                  size={IconSize.Large}
                />
              )
            }
            {
              idx >= (breadcrumbItems.length - 1) && (
                <p className={`body-l ${styles.current}`} key={idx} {...(breadcrumbItems.length === idx + 1 && { 'aria-current': 'page' })}>{breadcrumbItems[idx].title}</p>
              )
            }
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
