import React, {
  useEffect,
  useState,
  Fragment
} from 'react'
import {
  m,
  LazyMotion,
  domAnimation
} from 'framer-motion'
import reactStringReplace from 'react-string-replace'
import { BreadcrumbJsonLd } from 'next-seo'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import Breadcrumb from '@/components/molecules/Breadcrumb'
import ButtonLink from '@/components/atoms/ButtonLink'
import Tag from '@/components/atoms/Tag'

// Styling
import styles from './HeaderBase.module.scss'
import Strikethrough from './Strikethrough'
import { RotatingAnimation } from './RotatingAnimation'

// Utils
import { capitalize } from '@/utils'

// Types
import type { ItemListElements } from 'next-seo/lib/jsonld/breadcrumb'

export interface HeaderBaseProps {
  title: string
  rotatingTitles?: ListObject[]
  description?: string | null
  breadcrumb?: LinkType[]
  hasSmallDescription?: boolean
  buttonTarget?: string
  buttonText?: string
  single: boolean
  isJobOpeningClosed?: boolean
  hasBreadcrumb?: boolean
}

interface RotatingTileProps {
  title: string,
  rotatingTitles: ListObject[]
}

const RotatingTile: React.FC<RotatingTileProps> = ({
  title,
  rotatingTitles
}) => {
  const items: ListObject[] = []
  const values = Object.values(rotatingTitles)
  items.push(...values)

  const regex = /{{|}}/g

  const output = reactStringReplace(title, regex, () => (
    <RotatingAnimation items={items} />
  ))

  return (
    <h1 className={styles['flex-header']}>
      {/*
       / Add <span> around every 'part' of the array to apply the flex layout
      */}
      {output.map((part, idx) => {
        if (typeof part === 'string') {
          return (
            <Fragment key={idx}>
              <span>{part}</span>
              {
                idx === 0 ? <span>&nbsp;</span> : null
              }
            </Fragment>
          )
        }

        return (
          <Fragment key={idx}>
            {part}
          </Fragment>
        )
      })}
    </h1>
  )
}

const HeaderBase: React.FC<HeaderBaseProps> = ({
  title,
  rotatingTitles,
  description,
  breadcrumb = [],
  hasSmallDescription,
  buttonTarget,
  buttonText,
  single,
  isJobOpeningClosed,
  hasBreadcrumb
}) => {
  const [rotatingTitleIndex, setRotatingTitleIndex] = useState(0)

  useEffect(() => {
    // Only initialize the interval when rotatingTitles is defined
    if (!rotatingTitles) { return }

    // Set a max index based on the amount of titles
    const max = Object.keys(rotatingTitles).length

    const intervalID = setTimeout(() => {
      // 'setRotatingTitleIndex' to the next index or back to zero when max is reached. Max is subtracted one because arrays start at zero.
      setRotatingTitleIndex((idx) => (idx < max - 1) ? idx + 1 : 0)
    }, 4000)

    return () => clearInterval(intervalID)
  }, [
    rotatingTitleIndex,
    setRotatingTitleIndex,
    rotatingTitles
  ])

  // Replaces bad with a strike through element.
  const parsedTitle = reactStringReplace(title, '~bad~', () => (
    <LazyMotion features={domAnimation} strict>
      <m.s className={styles.bad}>
        bad
        <Strikethrough className={styles.strike} />
      </m.s>
    </LazyMotion>
  ))

  const breadcrumbJsonLdItems = breadcrumb.map((item: LinkType, idx) => ({
    position: idx + 1,
    name: capitalize(item.title!),
    item: `https://yummygum.com${item.path}`
  }))

  return (
    <>
      <BreadcrumbJsonLd
        itemListElements={breadcrumbJsonLdItems as ItemListElements[]}
      />
      <Grid className={styles.header} cols={12} size="l">
        <Col span={10} span1Xl={8} start={2}>
          { (breadcrumb.length > 0 && hasBreadcrumb) && (
            <Breadcrumb
              className={styles.breadcrumb}
              items={breadcrumb}
              single={single}
            />
          )}

          <div className={styles.text} id="fill-in-form">
            {isJobOpeningClosed && (
              <Tag category="closed" className={styles.tag}>Job opening closed</Tag>
            )}

            {
              rotatingTitles && Object.keys(rotatingTitles).length > 0
                ? (
                  <RotatingTile
                    rotatingTitles={rotatingTitles}
                    title={title}
                  />
                )
                : (
                  <h1>{parsedTitle}</h1>
                )
            }

            {
              description && (
                <div
                  className={`
                  body-l
                  underline-inline
                  ${styles.description}
                  ${hasSmallDescription ? `${styles.right}` : ''}
                `}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )
            }

            {buttonTarget && (
              <ButtonLink
                className={styles.button}
                href={buttonTarget}
              >
                {buttonText}
              </ButtonLink>
            )}
          </div>
        </Col>
      </Grid>
    </>
  )
}

export default HeaderBase
