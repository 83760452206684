// Utils
import { capitalize } from '@/utils'

export const generateBreadcrumbs = (base: BreadcrumbProps, items?: BreadcrumbProps[], title?: string | null) => {
  // When page of url doesn't exist, return empty url
  let url = base.path ? `/${base.path}` : ''

  const breadcrumb: BreadcrumbProps[] = [
    {
      title: base.title,
      path: url
    }
  ]

  items?.map((item) => {
    const elem: BreadcrumbProps = {
      title: '',
      path: ''
    }

    url += `/${item.path}`

    elem.path = url
    elem.title = capitalize(item.title).replace(/-/g, ' ')

    breadcrumb.push(elem)
  })

  if (title) {
    // Replace the last element of the array with the title of the page
    breadcrumb[breadcrumb.length - 1].title = title
  }

  if (base.title === 'legal') {
    return items
  }

  return breadcrumb
}

export default generateBreadcrumbs
